import React from "react";
import PropTypes from "prop-types";
import CommunityHeroCarousel from "./../CommunityHeroCarousel";
import CommunityBody from "./../CommunityBody";
import { SmallButton, BigButton } from "../Buttons";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { H3,H6, H6Medium, H6SmallMedium, H5Strong } from "./../Typography";
import {
  getShortDayNoTimeZone,
  getDateDayNoTimeZone,
  cleanCraftDate,
} from "./../../modules/time";
const ResultGrid = styled.div`
display: grid;
grid-template-rows: auto;
grid-template-columns: 400px 400px;
grid-column-gap: 20px;
grid-row-gap: var(--rowGap);
grid-auto-flow: dense;
margin-bottom: 50px;
@media (max-width: 767px)
{
  display:block;
  grid-template-columns: 0px;
  grid-column-gap:0px;
}
`;


const EventCardDiv = styled.div`
  --color: ${(props) => props.color || "var(--red)"};
  --cardWidthExtraWide: 820px;
  --cardWidthWide: 820px;
  --cardWidthNormal: 400px;
  --extraWideWidth: 610px;
  --extraWideHeight: 450px;
  --wideWidth: 400px;
  --wideHeight: 300px;
  --normalWidth: 190px;
  --normalHeight: 190px;
  display: flex;
  border-top: 1.5px solid var(--color);
  padding-top: 20px;
  width: ${(props) =>
    props.size === 2
      ? "var(--cardWidthExtraWide)"
      : props.size === 1
        ? "var(--cardWidthWide)"
        : "var(--cardWidthNormal)"};
  grid-column-end: ${(props) =>
    props.size === 2 || props.size === 1 ? "span 2" : "span 1"};
  border-bottom: var(--bottomRule);
  padding-bottom: var(--bottomPadding);

  & > a > .gatsby-image-wrapper {
    object-fit: cover;
    margin-right: 20px;
    height: ${(props) =>
    props.size === 2
      ? "var(--extraWideHeight)"
      : props.size === 1
        ? "var(--wideHeight)"
        : "var(--normalHeight)"} !important;
    min-width: ${(props) =>
    props.size === 2
      ? "var(--extraWideWidth)"
      : props.size === 1
        ? "var(--wideWidth)"
        : "var(--normalWidth)"};
    max-width: ${(props) =>
    props.size === 2
      ? "var(--extraWideWidth)"
      : props.size === 1
        ? "var(--wideWidth)"
        : "var(--normalWidth)"};
  }
  & > a > div {
    margin-right: 20px;
  }
  & > a > .placeholder {
    width: 190px;
    height: 190px;
    background-color: var(--color);
  }
  & h6 a {
    color: var(--textColor);
  }
  & > div {
    & > h6:first-of-type {
      color: var(--color);
      font-weight: 600;
    }
    & > h6 + h6 {
      margin-top: 12px;
      & strong {
        display: inline-block;
        width: 40px;
      }
    }
    & > h5 {
      margin-top: 12px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
      & a {
        color: var(--textColor);
      }
    }
    & > div {
      font-size: 12px;
      line-height: 18px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 10px;
    & > div {
      // padding: 0 25px;
      & h6 {
        text-align: center;
        margin-top: 15px;
      }
      & h5 {
        text-align: center;
      }
    }
  }
  @media (max-width: 1439px) {
    --cardWidthWide: 400px;
    --wideWidth: 190px;
    --wideHeight: 190px;
    /* & .gatsby-image-wrapper {

		} */
  }
  @media (max-width: 1023px) {
    --cardWidthExtraWide: 400px;
    --extraWideWidth: 190px;
    --extraWideHeight: 190px;
    grid-column-end: span 1;
  }
  @media (max-width: 767px) {
    & > div > div {
      text-align: center;
    }
  }
`;

const VirtualTourGalleryWrapper = styled.div`
  padding: var(--outerMargin);
  position: relative;
  margin: auto;
    width: 55%;
    padding: 10px;
    @media (max-width: 767px){
  
      width: 100%;
      padding:20px;
    }
`;

const startDate = (thisEvent) => {
  return (
    getShortDayNoTimeZone(cleanCraftDate(thisEvent.eventStartDateTime)) ===
      getShortDayNoTimeZone(cleanCraftDate(endDateToUse(thisEvent))) ? (
      <H6Medium>
        {getShortDayNoTimeZone(cleanCraftDate(thisEvent.eventStartDateTime))}
      </H6Medium>
    ) : (
      <React.Fragment>
        <H6Medium>
          {hasItStarted(thisEvent)
            ? "Now on until "
            : `${getShortDayNoTimeZone(
              cleanCraftDate(thisEvent.eventStartDateTime)
            )}–`}
          {getShortDayNoTimeZone(cleanCraftDate(endDateToUse(thisEvent)))}
        </H6Medium>
      </React.Fragment>
    )
  )
}

const startDateEditorial = (thisEvent) => {
  return (
    getShortDayNoTimeZone(cleanCraftDate(thisEvent.editorialDate)) ===
      getShortDayNoTimeZone(cleanCraftDate(endDateToUseEditorial(thisEvent))) ? (
      <H6Medium>
        {getShortDayNoTimeZone(cleanCraftDate(thisEvent.editorialDate))}
      </H6Medium>
    ) : (
      <React.Fragment>
        <H6Medium>
          {hasItStartedEditorial(thisEvent)
            ? "Now on until "
            : `${getShortDayNoTimeZone(
              cleanCraftDate(thisEvent.editorialDate)
            )}–`}
          {getShortDayNoTimeZone(cleanCraftDate(endDateToUseEditorial(thisEvent)))}
        </H6Medium>
      </React.Fragment>
    )
  )
}

const isThereAnEndDate = (thisEvent) => {
  return Boolean(thisEvent.eventEndDateTime);
};
const endDateToUse = (thisEvent) => {
  return (isThereAnEndDate ? thisEvent.eventEndDateTime : thisEvent.eventStartDateTime);
}
const hasItStarted = (thisEvent) => {
  return (cleanCraftDate(thisEvent.eventStartDateTime) < new Date());
}

const isThereAnEndDateEditorial = (thisEvent) => {
  return Boolean(thisEvent.eventEndDateTime);
};
const endDateToUseEditorial = (thisEvent) => {
  return (isThereAnEndDateEditorial ? thisEvent.eventEndDateTime : thisEvent.editorialDate);
}
const hasItStartedEditorial = (thisEvent) => {
  return (cleanCraftDate(thisEvent.editorialDate) < new Date());
}

const CommunityMember = ({
  communityMemberName,
  subtitle,
  communityMemberType,
  communityMemberImages,
  communityMemberContactDetails,
  communityMemberDescription,
  communityMemberExtendedDescription,
  communityMemberEmailAddress,
  communityMemberLocation,
  communityMemberOpeningHours,
  communityMemberWebsite,
  relatedEvents,
  relatedEditorails
}) => (
  <div>
    <CommunityHeroCarousel
      communityMemberName={communityMemberName}
      subtitle={subtitle}
      communityMemberType={communityMemberType}
      communityMemberImages={communityMemberImages}
    />
    <CommunityBody
      communityMemberContactDetails={communityMemberContactDetails}
      communityMemberDescription={communityMemberDescription}
      communityMemberExtendedDescription={communityMemberExtendedDescription}
      communityMemberEmailAddress={communityMemberEmailAddress}
      communityMemberLocation={communityMemberLocation}
      communityMemberOpeningHours={communityMemberOpeningHours}
      communityMemberWebsite={communityMemberWebsite}
      relatedEvents={relatedEvents}
      relatedEditorails={relatedEditorails}
      goToMap={(e) => {
        console.log(`Map called: ${e}`);
      }}
    />
    
    {relatedEvents.length? (
      <VirtualTourGalleryWrapper>
        <H3 style={{color: "#ff644e",paddingBottom:"15px"}}>Related Content</H3>
      <ResultGrid>
        {relatedEvents.map((thisEvent, index) => (
          <EventCardDiv key={index}>
            <Link to={`/event/${thisEvent.node.slug}/`}>
              {thisEvent.node.headerImage &&
                thisEvent.node.headerImage.length &&
                thisEvent.node.headerImage[0].card ?
                (
                  thisEvent.node.headerImage[0].card.childImageSharp ? (
                    <Img
                      fixed={thisEvent.node.headerImage[0].card.childImageSharp.fixed}
                      alt={thisEvent.node.title}
                    />
                  ) : (
                    <img
                      src={thisEvent.node.headerImage[0].card.publicURL}
                      alt={thisEvent.node.title}
                    />
                  )
                ) : (
                  <div className="placeholder" />
                )}
            </Link>
            <div>
              <H6SmallMedium>
                {thisEvent.node.eventType[0]}
              </H6SmallMedium>
              {thisEvent && thisEvent.node.eventType && thisEvent.node.eventType.length ? (
                <H6>
                  {thisEvent.node.eventLocation[0] && thisEvent.node.eventLocation[0].slug ? (
                    thisEvent.node.eventLocation[0].typeHandle === "communityMember" ? (
                      <Link to={`/community/${thisEvent.node.eventLocation[0].slug}/`}>
                        {thisEvent.node.eventLocation[0].title}
                      </Link>
                    ) : (
                      <a>{thisEvent.node.eventLocation[0].title}</a>
                    )
                  ) : null}
                </H6>
              ) : null}
              {startDate(thisEvent.node)}
              <H5Strong>
                <Link to={`/event/${thisEvent.node.slug}/`}>{thisEvent.node.title}</Link>
              </H5Strong>
              <div
                dangerouslySetInnerHTML={{ __html: thisEvent.node.eventShortDescription }}
              />
            </div>
          </EventCardDiv>
        ))}
        
      </ResultGrid>
      </VirtualTourGalleryWrapper>
    ) :
      null
    }
    {relatedEditorails.length? (
      <VirtualTourGalleryWrapper>
      <H3 style={{color: "#ff644e",paddingBottom:"15px"}}>Delve In</H3>
      <ResultGrid>
        {relatedEditorails.map((thisEvent, index) => (
          <EventCardDiv key={index}>
          <Link to={`/${thisEvent.node.typeHandle}/${thisEvent.node.slug}/`}>
          {thisEvent.node.headerImage &&
                thisEvent.node.headerImage.length &&
                thisEvent.node.headerImage[0].card ?
                (
                  thisEvent.node.headerImage[0].card.childImageSharp ? (
                    <Img
                      fixed={thisEvent.node.headerImage[0].card.childImageSharp.fixed}
                      alt={thisEvent.node.title}
                    />
                  ) : (
                    <img
                      src={thisEvent.node.headerImage[0].card.publicURL}
                      alt={thisEvent.node.title}
                    />
                  )
                ) : (
                  <div className="placeholder" />
                )}
          </Link>
          <div>
          <H6SmallMedium>
                {thisEvent.node.editorialType[0]}
          </H6SmallMedium>
           
          <H5Strong>
                <Link to={`/${thisEvent.node.typeHandle}/${thisEvent.node.slug}/`}>{thisEvent.node.title}</Link>
              </H5Strong>
              <div
                dangerouslySetInnerHTML={{ __html: thisEvent.node.editorialShortDescription }}
              />
          </div>
        </EventCardDiv>
        ))}
        
      </ResultGrid> 
      </VirtualTourGalleryWrapper>
    ) :
      null
    }
    
  </div>
);

export default CommunityMember;

CommunityMember.propTypes = {
  communityMemberContactDetails: PropTypes.arrayOf(
    PropTypes.shape({ method: PropTypes.string, value: PropTypes.string })
  ),
  communityMemberDescription: PropTypes.string,
  communityMemberEmailAddress: PropTypes.string,
  communityMemberLocation: PropTypes.string,
  communityMemberOpeningHours: PropTypes.string,
  relatedEvents: PropTypes.array,
  relatedEditorails: PropTypes.array,
  communityMemberWebsite: PropTypes.string,
  communityMemberName: PropTypes.string,
  subtitle: PropTypes.string,
  communityMemberType: PropTypes.string,
  communityMemberImages: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.element,
      link: PropTypes.string,
    })
  ),
};
