import styled from "styled-components";

export const CommunityBodyDiv = styled.div`
  display: flex;
  padding: 20px var(--outerMargin);
  max-width: var(--width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  & .contactdetails {
    overflow-x: auto;

    & h5 {
      white-space: nowrap;
    }
  }
  & .tablet {
    display: none;
  }
  & .desktop {
    display: flex;
  }
  & h5:first-child {
    color: var(--red);
  }
  & a {
    color: var(--black);
    text-decoration: underline;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    &.nounderline {
      text-decoration: none;
    }
    &:hover {
      color: var(--red);
    }
  }
  & > div {
    display: flex;
    flex-direction: column;
  }
  & > div:first-of-type {
    min-width: 190px;
    margin-right: 20px;
    & h5 {
      margin-bottom: 10px;
    }
    & + div {
      min-width: 820px;
      & p {
        font-size: 18px;
        line-height: 1.4;
        margin: 0;
        & + p {
          margin-top: 18px;
        }
      }
      & + div > div {
        margin-left: 20px;
        min-width: 295px;
        & > div {
          margin-bottom: 20px;
          & > button {
            display: inline;
          }
          & > div > h5 {
            color: var(--black);
          }
        }
      }
    }
  }
  @media (max-width: 1439px) {
    & > div:first-of-type + div {
      min-width: initial;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
    padding: 50px 72px;
    & .tablet {
      display: flex;
    }
    & .desktop {
      display: none;
    }
    & > div:first-of-type + div {
      & + div {
        margin-left: 0;
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        & > div:first-of-type {
          margin-left: 0;
        }
        & > div + div {
          display: flex;
          flex-direction: column;
          & h5 {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 25px 20px;
    & > div:first-of-type {
      margin-right: 0;
      margin-bottom: 25px;
      & + div + div {
        margin-left: 0;
        margin-top: 25px;
      }
    }
  }
`;
