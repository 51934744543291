import React from "react";
import PropTypes from "prop-types";
import MiscSocialSmall from "./../icons/MiscSocialSmall";
import { SmallButton } from "./../Buttons";
import { H5SmallStrong, H5Big } from "./../Typography";
import { CommunityBodyDiv } from "./elements";
import { navigate } from "gatsby";


const CommunityBody = ({
  communityMemberContactDetails,
  communityMemberDescription,
  communityMemberExtendedDescription,
  communityMemberEmailAddress,
  communityMemberLocation,
  communityMemberOpeningHours,
  communityMemberWebsite,
  goToMap,
}) => {
  return (
    <CommunityBodyDiv>
      <div className="desktop">
        <H5SmallStrong>Share</H5SmallStrong>
        <MiscSocialSmall color={"var(--red)"} />
      </div>
      <div className="description">
        <div dangerouslySetInnerHTML={{ __html: communityMemberDescription }} />
        <div
          dangerouslySetInnerHTML={{
            __html: communityMemberExtendedDescription,
          }}
        />
      </div>
      <div>
        <div>
          <div>
            <H5SmallStrong>Contact details</H5SmallStrong>
            <div className="contactdetails">
              {communityMemberContactDetails &&
              communityMemberContactDetails.length
                ? communityMemberContactDetails.map((contactDetail, index) => (
                    <H5Big key={index}>
                      {contactDetail.value.indexOf("http") > -1 ? (
                        <a
                          href={contactDetail.value}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {contactDetail.method}
                        </a>
                      ) : contactDetail.value.indexOf("+971") > -1 ? (
                        <a
                          href={`tel:${contactDetail.value}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="nounderline"
                        >
                          {contactDetail.method} {contactDetail.value}
                        </a>
                      ) : (
                        `${contactDetail.method} ${contactDetail.value}`
                      )}
                    </H5Big>
                  ))
                : null}
            </div>
            <H5Big>
              <a
                href={`mailto:${communityMemberEmailAddress}`}
                target="_blank"
                rel="noopener noreferrer"
                className="nounderline"
              >
                {communityMemberEmailAddress}
              </a>
            </H5Big>
            <H5Big>
              <a
                href={communityMemberWebsite}
                target="_blank"
                rel="noopener noreferrer"
                className="nounderline"
              >
                {communityMemberWebsite}
              </a>
            </H5Big>
          </div>
          <div>
            <H5SmallStrong>Opening hours</H5SmallStrong>
            <H5Big>{communityMemberOpeningHours}</H5Big>
          </div>
          <div>
            <H5SmallStrong>Location</H5SmallStrong>
            <H5Big>{communityMemberLocation}</H5Big>
          </div>
          <p>
            <SmallButton
              onClick={() => {
                navigate(
                  `/explore-our-district?location=${encodeURI(
                    communityMemberLocation
                  )}`
                );
                // goToMap(communityMemberLocation);
              }}
            >
              View on map
            </SmallButton>
          </p>
        </div>
        <div className="tablet">
          <H5SmallStrong>Share</H5SmallStrong>
          <MiscSocialSmall color={"var(--red)"} />
        </div>
      </div>
    </CommunityBodyDiv>
  );
};

export default CommunityBody;

CommunityBody.propTypes = {
  communityMemberContactDetails: PropTypes.arrayOf(
    PropTypes.shape({ method: PropTypes.string, value: PropTypes.string })
  ),
  communityMemberDescription: PropTypes.string,
  communityMemberEmailAddress: PropTypes.string,
  communityMemberLocation: PropTypes.string,
  communityMemberOpeningHours: PropTypes.string,
  communityMemberWebsite: PropTypes.string,
  goToMap: PropTypes.func,
};
