import React from "react";
import { graphql } from "gatsby";
import Layout from "./../components/Layout";
import CommunityMember from "./../components/CommunityMember";

/**
 * This is a generic page template.
 *
 * TODO: a wrapper for the content.
 *
 */


const CommunityMemberPageTemplate = ({ data, pageContext }) => {
  const {
    title,
    subtitle,
    headerImage,
    communityMemberDescription,
    richTextField,
    communityMemberEmailAddress,
    communityMemberImages,
    communityMemberLocation,
    communityMemberType,
    communityMemberWebsite,
    communityMemberOpeningHours,
    communityMemberContactDetails,
  } = data.craftEntryInterface;

  const now = new Date();
  
  const relatedEvents = data.allCraftEventEventEntry.edges.filter((x) => {
    const a='show'
    //console.log(x.node.eventEndDateTime);
    const thisDate = new Date(x.node.eventEndDateTime || x.node.eventStartDateTime);
    return (thisDate >= now);
  });


  const relatedArticlesSounds = data.allCraftArticlesSoundsEntry.edges.filter((x) => {
    const thisDate = new Date(x.node.editorialDate);
    const a=(x.node.hideInCommunityDetails 
      && x.node.hideInCommunityDetails.length 
      && x.node.hideInCommunityDetails[0] 
      && x.node.hideInCommunityDetails[0]=='yes'?'hide':'show')
    return (a=='show');
  });
  const relatedArticlesVideos = data.allCraftArticlesVideosEntry.edges.filter((x) => {
    const thisDate = new Date(x.node.editorialDate);
    const a=(x.node.hideInCommunityDetails 
      && x.node.hideInCommunityDetails.length 
      && x.node.hideInCommunityDetails[0] 
      && x.node.hideInCommunityDetails[0]=='yes'?'hide':'show')
    return (a=='show');
  });
  const relatedArticlesWords = data.allCraftArticlesWordsEntry.edges.filter((x) => {
    const thisDate = new Date(x.node.editorialDate);
    const a=(x.node.hideInCommunityDetails 
      && x.node.hideInCommunityDetails.length 
      && x.node.hideInCommunityDetails[0] 
      && x.node.hideInCommunityDetails[0]=='yes'?'hide':'show')
    return (a=='show'); 
  });

  const relatedArticlesImages = data.allCraftArticlesImagesEntry.edges.filter((x) => {
    const thisDate = new Date(x.node.editorialDate);
    const a=(x.node.hideInCommunityDetails 
      && x.node.hideInCommunityDetails.length 
      && x.node.hideInCommunityDetails[0] 
      && x.node.hideInCommunityDetails[0]=='yes'?'hide':'show')
    return (a=='show');
  });

  const relatedEditorails = [...relatedArticlesSounds, ...relatedArticlesVideos, ...relatedArticlesWords,...relatedArticlesImages];
  
  // console.log(data, pageContext);
  // console.log(communityMemberImages, pageContext);
  // console.log(subtitle, communityMemberDescription);
  return (
    <Layout
      footerColor={"var(--red)"}
      title={title}
      SEODescription={communityMemberDescription}
      SEOImageURL={
        headerImage &&
          headerImage.length &&
          headerImage[0].SEOImage &&
          headerImage[0].SEOImage.childImageSharp
          ? `https://alserkal.online${headerImage[0].SEOImage.childImageSharp.fixed.src}`
          : null
      }
    >
      <div>
        <CommunityMember
          communityMemberContactDetails={communityMemberContactDetails}
          communityMemberDescription={communityMemberDescription}
          communityMemberExtendedDescription={richTextField}
          communityMemberEmailAddress={communityMemberEmailAddress}
          communityMemberImages={
            communityMemberImages.length
              ? communityMemberImages
                .filter((x) => x && x.slide && x.slide.childImageSharp)
                .map((x) => x.slide.childImageSharp)
              : headerImage.length &&
                headerImage[0].wide &&
                headerImage[0].wide.childImageSharp
                ? [headerImage[0].wide.childImageSharp]
                : []
          }
          communityMemberLocation={communityMemberLocation}
          communityMemberName={title}
          communityMemberOpeningHours={communityMemberOpeningHours}
          subtitle={subtitle}
          communityMemberType={communityMemberType[0]}
          communityMemberWebsite={communityMemberWebsite}
          relatedEvents={relatedEvents}
          relatedEditorails={relatedEditorails}
        />
      </div>
    </Layout> 
  );
};

export default CommunityMemberPageTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    craftEntryInterface(id: { eq: $id }) {
      ... on Craft_community_communityMember_Entry {
        id
        communityMemberDescription
        richTextField
        communityMemberEmailAddress
        communityMemberImages {
          ... on Craft_communityAssets_Asset {
            SEOImage: localFile {
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
            slide: localFile {
              publicURL
              childImageSharp {
                fixed(width: 820, height: 600, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        communityMemberContactDetails {
          method
          value
        }
        communityMemberWebsite
        communityMemberType
        subtitle
        communityMemberSlug
        communityMemberOpeningHours
        communityMemberLocation
        headerImage {
          ... on Craft_communityAssets_Asset {
            SEOImage: localFile {
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
            wide: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1240, height: 500, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_editorialAssets_Asset {
            SEOImage: localFile {
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
            wide: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1240, height: 500, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        title
      }
    }
    allCraftEventEventEntry(
      filter: {eventLocation: {elemMatch: {id: {eq: $id}}}} 
    ) {
      edges {
        node {
          id
          title
          slug
          eventType
          eventShortDescription
          eventLocation {
            title
            slug
            typeHandle
          }
          eventEndDateTime
          eventStartDateTime
          headerImage {
            ... on Craft_editorialAssets_Asset {
              card: localFile {
                publicURL
                childImageSharp {
                  fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
    allCraftArticlesImagesEntry(
      filter: {eventLocation: {elemMatch: {id: {eq: $id}}}}
    ) {
      edges {
        node {
          id
          title
          slug
          editorialDate
          typeHandle
          editorialType
          editorialShortDescription
          hideInCommunityDetails
          eventLocation {
            title
            slug
            typeHandle
          }
          headerImage {
            ... on Craft_editorialAssets_Asset {
              card: localFile {
                publicURL
                childImageSharp {
                  fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
    allCraftArticlesSoundsEntry(
      filter: {eventLocation: {elemMatch: {id: {eq: $id}}}}
    ) {
      edges {
        node {
          id
          title
          slug
          editorialDate
          typeHandle
          editorialType
          editorialShortDescription
          hideInCommunityDetails
          eventLocation {
            title
            slug
            typeHandle
          }
          headerImage {
            ... on Craft_editorialAssets_Asset {
              card: localFile {
                publicURL
                childImageSharp {
                  fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
    allCraftArticlesVideosEntry(
      filter: {eventLocation: {elemMatch: {id: {eq: $id}}}}
    ) {
      edges {
        node {
          id
          title
          slug
          editorialDate
          editorialShortDescription
          hideInCommunityDetails
          typeHandle
          editorialType
          eventLocation {
            title
            slug
            typeHandle
          }
          headerImage {
            ... on Craft_editorialAssets_Asset {
              card: localFile {
                publicURL
                childImageSharp {
                  fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
    allCraftArticlesWordsEntry(
      filter: {eventLocation: {elemMatch: {id: {eq: $id}}}}
    ) {
      edges {
        node {
          id
          title
          slug
          editorialDate
          typeHandle
          editorialType
          editorialShortDescription
          hideInCommunityDetails
          eventLocation {
            title
            slug
            typeHandle
          }
          headerImage {
            ... on Craft_editorialAssets_Asset {
              card: localFile {
                publicURL
                childImageSharp {
                  fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
